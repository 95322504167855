<template>
  <div>
    <VLoading v-bind:loading="loading" />
    <VJobToolbar v-if="job" v-bind:job="job" class="mb-3" />
    <div v-if="product">
      <b-row>
        <b-col>
          <b-card>
            <b-card-title>
              <b-row>
                <b-col
                  >Product
                  <VClipCopy
                    :from="'vproductm'"
                    v-bind:uuid="product.id"
                    v-bind:name="'product'"
                    v-bind:key="'product-' + product.id"
                  />
                </b-col>
                <b-col>
                  <span class="float-right">
                    <b-form-checkbox
                      v-model="product.verified"
                      name="check-button"
                      @change="verifyProduct()"
                      switch
                    >
                      {{ product.verified ? "Verified" : "Not verified" }}
                    </b-form-checkbox>
                  </span>
                  <span class="float-right">
                    <b-button
                      class="m-1"
                      v-on:click="saveproduct()"
                      variant="outline-success"
                      v-b-tooltip.hover
                      title="Save product"
                    >
                      <i class="fal fa-save"></i>
                    </b-button>

                    <b-button
                      class="m-1"
                      v-on:click="loadProduct()"
                      variant="outline-primary"
                      v-b-tooltip.hover
                      title="Refresh product"
                    >
                      <i class="fal fa-sync-alt"></i>
                    </b-button>

                    <b-button
                      class="m-1"
                      v-on:click="removeproduct()"
                      variant="outline-danger"
                      v-b-tooltip.hover
                      title="Remove product"
                    >
                      <i class="fal fa-trash-alt"></i>
                    </b-button>

                    <VUpload
                      class="ml-1"
                      v-bind:product_id="product_id"
                      v-bind:key="'product-' + product_id"
                    />
                  </span>
                </b-col>
              </b-row>
            </b-card-title>
            <b-card-sub-title>
              TYPE: {{ getCollectionName(product.product_collection).name }}
            </b-card-sub-title>

            <b-card-text class="mt-2" v-if="product">
              Name:
              <b-form-input class="mb-2" v-model="product.name"></b-form-input>
              Customer Id:
              <b-form-input
                class="mt-2 mb-2"
                v-model="product.customer_product_id"
              ></b-form-input>
              Product Set:
              <b-input-group class="mt-2 mb-2">
                <b-form-input v-model="product_set_id"></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="outline-success"
                    v-b-tooltip.hover
                    title="Save Product Set"
                    @click="saveProductSet()"
                  >
                    <i class="fal fa-save"></i>
                  </b-button>
                  <router-link
                    v-if="product.product_set"
                    :to="'/set/' + product.product_set.id"
                    target="_blank"
                  >
                    <b-button
                      variant="outline-primary"
                      v-b-tooltip.hover
                      title="Go to Product Set"
                    >
                      <i class="fal fa-external-link"></i>
                    </b-button>
                  </router-link>
                </b-input-group-append>
              </b-input-group>

              Created:
              <b-form-input
                class="mt-2"
                disabled
                v-model="product.created"
              ></b-form-input>
            </b-card-text>
          </b-card>

          <b-card class="mt-4">
            <b-card-text>
              <h4>
                Product images
              </h4>
            </b-card-text>
            <b-table
              :items="products"
              :fields="image_fields"
              class="mt-3"
              @row-clicked="onRowClicked"
              :tbody-tr-class="rowClass"
              outlined
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(name)="data">
                {{ data.item.name }}
                <VClipCopy
                  :from="'vproducti'"
                  v-bind:uuid="data.item.id"
                  v-bind:name="'Image'"
                  v-bind:key="'producti-' + data.item.id"
                />
              </template>

              <template v-slot:cell(thumb)="data">
                <VZoomImage
                  :id="data.item.id"
                  :image="data.item.file"
                  :thumb="data.item.thumb"
                  :name="'Image'"
                />
              </template>

              <template v-slot:cell(sync_status)="data">
                {{ data.item.sync_status }}
                <span v-if="data.item.sync_status == 'TO_SYNC'">
                  <span style="color: orange;">
                    <i class="far fa-exclamation-circle"></i>
                  </span>
                </span>
                <span v-else-if="data.item.sync_status == 'SYNCED'">
                  <span style="color: green;">
                    <i class="far fa-check-circle"></i>
                  </span>
                </span>
                <span v-else>
                  <span style="color: red;">
                    <i class="far fa-exclamation-circle"></i>
                  </span>
                </span>
              </template>

              <template v-slot:cell(objects)="data">
                <b-badge
                  v-if="JSON.stringify(data.item._objects) != '{}'"
                  variant="primary"
                  class="m-1"
                >
                  Objects
                </b-badge>
              </template>

              <template v-slot:cell(action)="data">
                <b-button
                  class="mr-1"
                  variant="outline-danger"
                  v-b-tooltip.hover
                  title="Remove Image"
                  @click="removeimage(data.item.id)"
                >
                  <i class="fal fa-trash"></i>
                </b-button>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col>
          <b-tabs content-class="mt-3">
            <b-tab title="Product Meta">
              <JSONEditor
                style="height: 200px;"
                :options="options"
                ref="editor"
                :onChange="onChange"
                :json="product.meta_data"
              />
            </b-tab>
          </b-tabs>

          <b-card class="mt-4">
            <b-card-text>
              <h4>
                Product prices
              </h4>
            </b-card-text>
            <b-table
              :items="prices"
              :fields="price_fields"
              class="mt-3"
              outlined
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(source.name)="data">
                <a :href="data.item.item_link">{{ data.item.source.name }} </a>
                <VClipCopy
                  :from="'vpricei'"
                  v-bind:uuid="data.item.id"
                  v-bind:name="'Product'"
                  v-bind:key="'pricei-' + data.item.id"
                />
              </template>

              <template v-slot:cell(img_link)="data">
                <VZoomImage
                  :id="data.item.id"
                  :image="data.item.img_link"
                  :thumb="data.item.img_link"
                  :name="'Image'"
                />
              </template>

              <template v-slot:cell(action)="data">
                <b-button
                  class="mr-1"
                  variant="outline-danger"
                  v-b-tooltip.hover
                  title="Remove Price"
                  @click="removeprice(data.item.id)"
                >
                  <i class="fal fa-trash"></i>
                </b-button>
              </template>
            </b-table>
          </b-card>
          <b-pagination
            class="mt-3"
            v-if="!loading"
            v-model="currentPricePage"
            :total-rows="totalPriceRows"
            :per-page="perPricePage"
            label-page
            first-number
            last-number
            size="sm"
            hide-goto-end-buttons
          />
          <b-card title="Product image" class="mt-4" v-if="selected_image">
            <b-input-group prepend="name" class="mt-3 mb-4">
              <b-form-input v-model="selected_image.name"></b-form-input>
              <b-input-group-append>
                <b-button @click="saveimage" variant="outline-success"
                  >Save</b-button
                >
              </b-input-group-append>
            </b-input-group>
            <b-tabs content-class="mt-3">
              <b-tab title="Image">
                <img :src="selected_image.file" style="max-width: 100%;" />
              </b-tab>
              <b-tab title="Meta">
                <JSONEditor
                  style="height: 200px;"
                  :options="options"
                  ref="editor"
                  :onChange="onChangeImage"
                  :json="selected_image.meta_data"
                />
              </b-tab>
              <b-tab title="Objects">
                <JSONEditor
                  style="height: 200px;"
                  :options="options"
                  ref="editor"
                  :onChange="onChangeObjects"
                  :json="selected_image._objects"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-if="error">
      <b-container class="bv-example-row mt-3">
        <b-row>
          <b-col>
            <b-card class="container">
              <b-card-body>
                <b>product not found!</b>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { DataLoadedEnum } from "../../constants";
import VLoading from "@/components/VLoading.vue";
import VJobToolbar from "@/components/image/VJobToolbar.vue";
import JSONEditor from "vue2-jsoneditor";
import VUpload from "@/components/products/VUpload.vue";
import VClipCopy from "../utils/VClipCopy.vue";
import VZoomImage from "@/components/utils/VZoomImage.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VProduct",
  components: {
    JSONEditor,
    VJobToolbar,
    VLoading,
    VUpload,
    VClipCopy,
    VZoomImage
  },
  data() {
    return {
      product: null,
      products: [],
      prices: [],
      loading: true,
      perPricePage: 5,
      currentPricePage: 1,
      totalPriceRows: 0,
      image_fields: ["name", "thumb", "sync_status", "objects", "action"],
      price_fields: [
        { key: "source.name", label: "Source" },
        { key: "grade_company.name", label: "Grade C." },
        "img_link",
        "price",
        "currency",
        "date_of_creation"
      ],
      error: false,
      move_items: 0,
      msg_remove: false,
      selected_image: null,
      verified: [],
      options: {
        mode: "tree",
        modes: ["text", "tree", "view"],
        search: true,
        colorPicker: true,
        history: true
      }
    };
  },
  props: {
    job: Object,
    product_id: String
  },
  computed: {
    ...mapState(["data_loaded", "product_collections", "user_id", "workspace"]),
    product_set_id: {
      get() {
        return (this.product && this.product.product_set && this.product.product_set.id) || '';
      },
      set(value) {
        if (!this.product.product_set) {
          this.product.product_set = {};
        }
        this.product.product_set.id = value;
      }
    }
  },
  methods: {
    getCollectionName(type) {
      let item = this.product_collections.find(function(element) {
        return element.id == type;
      });
      if (item) {
        return item;
      } else {
        return "Unknown";
      }
    },
    onRowClicked(item, index, event) {
      this.selected_image = item;
    },
    copyToClipBoard: function(id) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    async onTypeChange(event) {
      console.log("Change stype:", event);
      let response = await axios
        .put(API_URL + "/similarity/training/v2/product/" + this.product_id, {
          type: event
        })
        .catch(error => {
          this.loading = false;
          this.error = true;
        });

      await this.loadProduct();
    },
    getTypeName(type) {
      let item = this.similarity_types.find(function(element) {
        return element.id == type;
      });
      if (item) {
        return item.name;
      } else {
        return "Unknown";
      }
    },
    async saveProductSet() {
      let response = await axios
        .put(
          API_URL +
            "/product/v2/product/" +
            this.product.id +
            "/?workspace=" +
            this.workspace,
          {
            product_collection: this.product.product_collection,
            product_set: this.product.product_set.id
          }
        )
        .catch(error => {
          this.$bvToast.toast(
            "Failed to update product set:" +
              JSON.stringify(error.response.data),
            {
              title: "Error",
              variant: "danger",
              solid: true
            }
          );
        });

      if (response) {
        this.$bvToast.toast("Product set updated successfully", {
          title: "Success",
          variant: "success",
          solid: true
        });
      }
    },
    async removeproduct() {
      let response = await axios
        .delete(
          API_URL +
            "/product/v2/product/" +
            this.product.id +
            "/?workspace=" +
            this.workspace
        )
        .catch(error => {
          this.loading = false;
          this.error = true;
        });
      this.product = null;
      this.error = true;
    },
    async saveimage() {
      let response = await axios
        .put(
          API_URL +
            "/product/v2/image/" +
            this.selected_image.id +
            "/?workspace=" +
            this.workspace,
          {
            name: this.selected_image.name,
            product: this.product.id,
            workspace: this.workspace
          }
        )
        .catch(error => {
          this.loading = false;
          this.error = true;
        });
    },
    async verifyProduct() {
      if (this.product.verified) {
        let response = await axios
          .post(
            API_URL + "/product/v2/product/" + this.product_id + "/verify",
            {
              product: this.product_id
            }
          )
          .catch(error => {
            this.loading = false;
            this.error = true;
          });
      } else {
        let response = await axios
          .post(
            API_URL + "/product/v2/product/" + this.product_id + "/unverify",
            {
              product: this.product_id
            }
          )
          .catch(error => {
            this.loading = false;
            this.error = true;
          });
      }
    },
    async saveproduct() {
      let response = await axios
        .put(
          API_URL +
            "/product/v2/product/" +
            this.product_id +
            "/?workspace=" +
            this.workspace,
          {
            name: this.product.name,
            customer_product_id: this.product.customer_product_id,
            product_collection: this.product.product_collection,
            workspace: this.product.workspace
          }
        )
        .catch(error => {
          this.loading = false;
          this.error = true;
        });
    },
    async loadProduct() {
      console.log("loadProduct...");
      let response = await axios
        .get(
          API_URL +
            "/product/v2/product/" +
            this.product_id +
            "/?workspace=" +
            this.workspace
        )
        .catch(error => {
          this.loading = false;
          this.error = true;
        });

      await this.loadImages();
      await this.loadPrices();

      // switch workspace
      if (this.workspace != response.data["workspace"]) {
        await this.$store.dispatch("changeWorkspace", {
          workspace: response.data["workspace"]
        });
      }

      if (response) {
        this.product = response.data;
        this.loading = false;
      } else {
        this.product = null;
        this.error = true;
        this.loading = false;
      }
    },
    fillSelected(product, field) {
      let items = product[field];
      for (let index = 0; index < items.length; index++) {
        product[field][index]["selected"] = false;
      }
    },
    async removeimage(item) {
      let response = await axios
        .delete(
          API_URL +
            "/product/v2/image/" +
            item +
            "/?workspace=" +
            this.workspace
        )
        .catch(error => {
          this.loading = false;
          this.error = true;
        });
      await this.loadImages();
    },
    async loadPrices() {
      this.prices = [];

      return new Promise((resolve, reject) => {
        axios
          .get(
            API_URL +
              "/product/v2/price/?workspace=" +
              this.workspace +
              "&product_id=" +
              this.product_id +
              "&page_size=" +
              this.perPricePage +
              "&page=" +
              this.currentPricePage
          )
          .then(
            response => {
              this.prices = response.data["results"];
              this.totalPriceRows = response.data["count"];

              resolve(response);
            },
            error => {
              reject(error);
            }
          );
      });
    },
    async loadImages() {
      this.products = [];

      return new Promise((resolve, reject) => {
        axios
          .get(
            API_URL +
              "/product/v2/image/?workspace=" +
              this.workspace +
              "&product=" +
              this.product_id
          )
          .then(
            response => {
              this.products = response.data["results"];
              resolve(response);
            },
            error => {
              reject(error);
            }
          );
      });
    },
    onChangeObjects(newJson) {
      axios
        .patch(
          API_URL +
            "/product/v2/image/" +
            this.selected_image.id +
            "/?workspace=" +
            this.workspace,
          {
            _objects: newJson
          }
        )
        .then(function(response) {}, function(error) {});
      this.selected_image._objects = newJson;
    },
    onChangeImage(newJson) {
      axios
        .patch(
          API_URL +
            "/product/v2/image/" +
            this.selected_image.id +
            "/?workspace=" +
            this.workspace,
          {
            meta_data: newJson
          }
        )
        .then(function(response) {}, function(error) {});
      this.selected_image.meta_data = newJson;
    },
    onChange(newJson) {
      axios
        .patch(
          API_URL +
            "/product/v2/product/" +
            this.product.id +
            "/?workspace=" +
            this.workspace,
          {
            meta_data: newJson
          }
        )
        .then(function(response) {}, function(error) {});
      this.product.meta_data = newJson;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (this.selected_image && this.selected_image.id == item.id)
        return "table-success";
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loadProduct();
      }
    },
    currentPricePage() {
      this.loadPrices();
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loadProduct();
    }
  },
  created() {
    this.$eventBus.$on(
      "product-id-refresh-" + this.product_id,
      this.loadProduct
    );
  },
  beforeDestroy() {
    this.$eventBus.$off("product-id-refresh-" + this.product_id);
  }
};
</script>

<style></style>
