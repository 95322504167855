<template>
  <b-modal
    :id="component_id"
    ref="modal"
    v-bind:title="title"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        label="Id"
        :state="nameState"
        label-for="name-input"
        v-if="id"
      >
        <b-form-input
          disabled
          id="input-live"
          v-model="id"
          aria-describedby="input-live-help input-live-feedback"
          placeholder=""
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Name" :state="nameState" label-for="name-input">
        <b-form-input
          id="input-live"
          v-model="name"
          :state="nameState"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Enter name of product set"
          trim
        ></b-form-input>

        <b-form-invalid-feedback id="input-live-feedback"
          >Enter at least 2 letters</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Year" label-for="year-input">
        <b-form-input
          id="year-input"
          v-model="year"
          placeholder="Enter year of product set"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Collection"
        label-for="table-style-variant"
        label-cols-lg="2"
      >
        <b-form-select
          v-model="collection_type"
          value-field="id"
          text-field="name"
          :options="product_collections"
          id="table-style-variant"
        >
          <template #first>
            <option value="">-- None --</option>
          </template>
        </b-form-select>

        <b-form-text v-if="error_message" id="input-live-help">
          <p class="text-danger">{{ error_message }}</p>
        </b-form-text>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { Compact } from "vue-color";
import { DataLoadedEnum } from "../../constants";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VSetCreate",
  components: {},
  props: {
    component_id: {
      type: String,
      default: "set-create"
    }
  },
  data() {
    return {
      id: "",
      name: "",
      customer_product_id: "",
      type: null,
      collection_type: null,
      title: "Create new product set",
      year: "",
      error_message: "",
      valid_message: "",
      activeWorkspace: ""
    };
  },
  computed: {
    ...mapState(["workspace", "data_loaded", "product_collections"]),
    nameState() {
      if (this.name) {
        return this.name.length > 1 ? true : false;
      }
      return false;
    }
  },
  methods: {
    checkFormValidity() {
      return [this.nameState].every(item => item);
    },
    resetModal() {
      this.error_message = "";
      this.valid_message = "";
    },
    handleOk(bvModalEvt) {
      // prevent modal from closing
      bvModalEvt.preventDefault();

      // trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.valid_message = "";
      this.error_message = "";

      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      this.createGroup();
    },
    updateValue(e) {
      this.color = { hex: e.hex };
    },
    async createGroup() {
      let data = {
        name: this.name,
        product_collection: this.collection_type,
        workspace: this.activeWorkspace.id,
        year: this.year
      };

      console.log("Active workspace", this.activeWorkspace);
      console.log("submitting ...", data);
      console.log("collection_type...", this.collection_type);

      let response;
      try {
        response = await axios.post(API_URL + "/product/v2/set/", data);
      } catch (error) {
        this.error_message =
          error.response && error.response.data
            ? JSON.stringify(error.response.data)
            : "Failed to create set";
        return;
      }

      this.$bvModal.hide(this.component_id);
      this.$eventBus.$emit("refresh-sets");
    }
  },
  mounted: function() {
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.activeWorkspace = this.$store.getters.getActiveWorkspace;
    }
  },
  watch: {
    data_loaded() {
      this.activeWorkspace = this.$store.getters.getActiveWorkspace;
    }
  }
};
</script>

<style></style>
