<template>
  <b-collapse
    :visible="sidebar_collapsed"
    id="sidebar-wrapper"
    class="sticky-top border-right border-gray sidebar-color"
  >
    <div>
      <div
        class="sidebar-heading sidebar-color border-new"
        style="height: 55px;"
      >
        <b-row>
          <b-col cols="2">
            <img
              style="width:1.3333em; height:1.3333em;"
              :src="`${publicPath}annotate-d.png`"
            />
          </b-col>
          <b-col>
            <div>
              <router-link to="/" class="router-nocolorhead m-2"
                >ANNOTATE
                <i v-if="dev != 'production'" class="fab fa-dev logo ml-1"></i>
              </router-link>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="sidebar-content">
        <div class="sidebar-items">
          <router-link class="router-nocolor" to="/">
            <div class="sidebar-item">
              <b-row>
                <b-col cols="2">
                  <i
                    class="icon-hover fad fa-chart-network mr-2 fa-lg blue-active"
                  ></i>
                </b-col>
                <b-col>Dashboard</b-col>
              </b-row>
            </div>
          </router-link>
          <router-link class="router-nocolor" to="/images">
            <div class="sidebar-item">
              <b-row>
                <b-col cols="2">
                  <i
                    class="icon-hover fad fa-image-polaroid mr-2 fa-lg blue-active"
                  ></i>
                </b-col>
                <b-col>Images</b-col>
              </b-row>
            </div>
          </router-link>

          <router-link
            class="router-nocolor"
            to="/detection"
            v-if="getCheckResource(ResourceEnum.DETECTION)"
          >
            <div class="sidebar-item">
              <b-row>
                <b-col cols="2">
                  <i
                    class="icon-hover fad fa-draw-square mr-2 fa-lg blue-active"
                  ></i>
                </b-col>
                <b-col>Detection</b-col>
              </b-row>
            </div>
          </router-link>

          <router-link
            class="router-nocolor"
            to="/segmentation"
            v-if="getCheckResource(ResourceEnum.SEGMENTATION)"
          >
            <div class="sidebar-item">
              <b-row>
                <b-col cols="2">
                  <i
                    class="icon-hover fad fa-draw-polygon mr-2 fa-lg blue-active"
                  ></i>
                </b-col>
                <b-col>Segmentation</b-col>
              </b-row>
            </div>
          </router-link>
          <router-link
            class="router-nocolor"
            to="/groups"
            v-if="getCheckResource(ResourceEnum.SIMILARITY)"
          >
            <div class="sidebar-item">
              <b-row>
                <b-col cols="2">
                  <i
                    class="icon-hover fad fa-photo-video mr-2 fa-lg blue-active"
                  ></i>
                </b-col>
                <b-col>Similarity</b-col>
              </b-row>
            </div>
          </router-link>
          <router-link
            class="router-nocolor"
            to="/jobs"
            v-if="getCheckResource(ResourceEnum.ANNOTATE)"
          >
            <div class="sidebar-item">
              <b-row>
                <b-col cols="2">
                  <i
                    class="icon-hover fad fa-shovel-snow mr-2 fa-lg blue-active"
                  ></i>
                </b-col>
                <b-col>Jobs</b-col>
              </b-row>
            </div>
          </router-link>
          <router-link
            class="router-nocolor"
            to="/products"
            v-if="getCheckResource(ResourceEnum.PRODUCT)"
          >
            <div class="sidebar-item">
              <b-row>
                <b-col cols="2">
                  <i
                    class="icon-hover fad fa-tshirt mr-2 fa-lg blue-active"
                  ></i>
                </b-col>
                <b-col>Products</b-col>
              </b-row>
            </div>
          </router-link>
          <router-link
            class="router-nocolor"
            to="/sets"
            v-if="getCheckResource(ResourceEnum.PRODUCT)"
          >
            <div class="sidebar-item">
              <b-row>
                <b-col cols="2">
                  <i
                    class="icon-hover fad fa-sim-card mr-2 fa-lg blue-active"
                  ></i>
                </b-col>
                <b-col>Product Sets</b-col>
              </b-row>
            </div>
          </router-link>
        </div>
        <div class="bottom-bar">
          <a
            href="https://docs.ximilar.com"
            class="router-nocolor"
            target="_blank"
          >
            <div class="sidebar-heading border-new-top">
              <i class="fad fa-book blue"></i> Docs
            </div>
          </a>
          <a
            href="https://app.ximilar.com"
            class="router-nocolor"
            target="_blank"
          >
            <div class="sidebar-heading border-new-top">
              <i class="fad fa-rocket app-style blue"></i> Ximilar APP
            </div>
          </a>
        </div>
      </div>
    </div>
  </b-collapse>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { DataLoadedEnum, ResourceEnum } from "../constants";

export default {
  name: "VSideBar",
  computed: {
    ...mapState(["sidebar_collapsed"]),
    ...mapGetters(["checkResource", "isActiveWorkspaceEvaluation"])
  },
  methods: {
    getCheckResource(resource) {
      return this.checkResource(resource);
    },
    toggleSidebar: function() {
      this.$store.dispatch("toggleSidebar");
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      ResourceEnum: ResourceEnum,
      dev: process.env.NODE_ENV
    };
  },
  mounted: function() {},
  props: {
    msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sidebar-color {
  background-color: #343a40;
}

@media (max-height: 600px) {
  .bottom-bar {
    width: 15rem;
  }
}

@media (min-height: 600px) {
  .bottom-bar {
    position: absolute;
    bottom: 0;
    width: 15rem;
  }
}

@media (max-height: 600px) {
  .sidebar-content {
    overflow-y: auto;
    height: calc(100vh - 55px);
  }
}

a.router-nocolor {
  color: white !important;
}

.router-nocolor:hover .icon-hover {
  color: white;
}

.router-link-exact-active .icon-hover {
  color: white;
}

.blue {
  --fa-secondary-opacity: 1;
  --fa-primary-color: white;
  --fa-secondary-color: #007bff;
}

.border-right {
  border-right: 1px solid rgba(180, 188, 199, 0.32) !important;
}

.border-new {
  border-bottom: 1px solid rgba(180, 188, 199, 0.32);
}

.border-new-top {
  border-top: 1px solid rgba(180, 188, 199, 0.32);
}

.sidebar-items {
  width: 15rem;
}

.sidebar-item {
  padding: 0.8rem 1.35rem;
  font-size: 0.95rem;
  color: #d8d8d8;
  font-weight: 500;
}

.router-link-exact-active .sidebar-item {
  background-color: #494c4e !important;
  font-weight: bold;
  color: white;
}

.router-link-exact-active .blue-active {
  --fa-secondary-opacity: 1;
  --fa-primary-color: white;
  --fa-secondary-color: #007bff;
}

.sidebar-item:hover {
  background-color: #5c6063;
}

.sidebar-button {
  color: black;
  text-decoration: none;
}

.logo {
  color: white;
}

a.router-nocolorhead {
  color: white; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

a.router-nocolorhead:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

a.router-nocolor {
  color: black; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
</style>
